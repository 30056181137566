.dashbord-card-line .progress {
   display: none;
}

.title-Dashboard {
   margin-top: -21px !important;
}

.underline {
   text-decoration: underline;
}