.text-item {
  text-decoration-line: none !important;
  color: black;
}

/* .rdt_TableHead{
    border-right: 0px solid #ddd;
    border-left:  0px solid #ddd;
    border-top:  0px solid #ddd;
}

.rdt_TableCol  .rdt_Table{
  border-right: 0px solid #ddd;
  border-left:  0px solid #ddd;
  border-top:  0px solid #ddd;
}
.rdt_TableHeadRow {
  border-right: 0px solid #ddd;
  border-left:  0px solid #ddd;
  border-top:  0px solid #ddd;
} */

.rdt_TableHeadRow .rdt_TableCol {
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;

}

.rdt_Table {
  border-left: 1px solid #ddd;

}

.payment-text {
  text-decoration-line: none;
  color: black;
  background-color: #cfc3c3;
  padding: 5px;
}