billTable {
 font-size: 12px;
 font-family: 'Times New Roman';
}

td,
th,
tr,
table {
 border-top: 1px solid black;
 border-collapse: collapse;
}

td.description,
th.description {
 width: 75px;
 max-width: 75px;
}

td.quantity,
th.quantity {
 width: 40px;
 max-width: 40px;
 word-break: break-all;
}

td.price,
th.price {
 width: 40px;
 max-width: 40px;
 word-break: break-all;
}

.centered {
 text-align: center;
 align-content: center;
}

.ticket {
 width: 155px;
 max-width: 155px;
}


@media print {

 .hidden-print,
 .hidden-print * {
  display: none !important;
 }
}