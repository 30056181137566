#table_div {
    overflow-x: auto !important;
}

#table_div table,
#table_div th,
#table_div td {
    border: 2px solid black;
    border-collapse: collapse;
    /* Ensures borders do not double up */
}

#table_div th,
#table_div td {
    padding: 8px;
}

#table_div tfoot tr td {
    border: none;
}

#table_div tfoot tr .total-border {
    border: 2px solid black;
}

#table_div tfoot tr .total-header {
    color: black;
    font-weight: 600;
}

#order-invoice .customer-details .order-date {
    font-size: 18px !important;
    font-weight: 700 !important;
    color: black !important ;
}